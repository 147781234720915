import { useLocation } from '@reach/router'
import Footer from 'components/Footer'
import { FooterButtonText, FooterOptionModel } from 'components/Footer/Footer'
import Navbar from 'components/Navbar'
import { NavbarModel } from 'components/Navbar/Navbar'
import { DarkModeProvider } from 'components/hooks/darkMode/useDarkModeContext'
import { IntersectionObserverProvider } from 'components/hooks/intersectionOberver/useIntersectionObserverContext'
import { graphql, useStaticQuery } from 'gatsby'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import React from 'react'
import { CookieConsent, Cookies } from 'react-cookie-consent'
import { pathsToIgnoreExp } from '../../utils/build/pathsConfig'
import { getDataByLocale, mappingEdge } from '../../utils/dataHelpers'
import { Locale } from '../../utils/enums'
import Seo, { MetaData } from '../Seo'
import './Page.scss'
import WhatsAppWidGet from 'components/WhatsAppWidGet'

type Props = {
    children?: JSX.Element | JSX.Element[]
    locale?: Locale
    metaData?: MetaData
    isSinglePage?: boolean
}

const constentText: Record<Locale, string> = {
    [Locale.en_US]:
        'This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website. If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.',
    [Locale.de_DE]:
        'Diese Webseite speichert Cookies auf deinem Computer. Diese Cookies werden verwendet, um Informationen darüber zu sammeln, wie du mit unserer Webseite interagierst und um dich wiedererkennen zu können. Wir nutzen diese Informationen, um deine Browsing-Erfahrung zu verbessern und zu personalisieren sowie für Analysen und Statistiken über unsere Besucher auf dieser Webseite. Wenn du ablehnst, werden deine Daten beim Besuch dieser Webseite nicht verfolgt. Ein einzelner Cookie wird in deinem Browser verwendet, um deine Präferenz, nicht verfolgt zu werden, zu speichern.',
}

export default ({ locale, children, metaData, isSinglePage }: Props): JSX.Element => {
    const location = useLocation()

    if (isSinglePage) {
        return (
            <DarkModeProvider>
                <IntersectionObserverProvider options={{ threshold: 0 }}>
                    <div className="page">
                        <div className="pageContainer">
                            <main>{children}</main>
                        </div>
                    </div>
                </IntersectionObserverProvider>
            </DarkModeProvider>
        )
    }
    const {
        allContentfulNavbar: { nodes: nodesNavBar },
        allContentfulFooterOption: { edges: nodesFooter },
        textButton1: { nodes: nodesTextButton1 },
    } = useStaticQuery(graphql`
        query Query {
            allContentfulNavbar {
                nodes {
                    node_locale
                    logo {
                        file {
                            url
                        }
                    }
                    darkLogo {
                        file {
                            url
                        }
                    }
                    contactUs
                    links {
                        path
                        text
                    }
                    languageLabel
                    theme
                }
            }
            allContentfulFooterOption(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        title
                        order
                        node_locale
                        items
                        link
                        footerItem {
                            anchor
                            text
                            redirect
                        }
                    }
                }
            }
            textButton1: allContentfulTextButton(
                filter: { contentful_id: { eq: "4K8OgIcs8MJejk7pcQtvVI" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    text
                }
            }
        }
    `)

    const {
        logo: {
            file: { url: logoUrl },
        },
        darkLogo: {
            file: { url: darkLogoUrl },
        },
        languageLabel,
        theme,
        contactUs,
        links,
    } = getDataByLocale<NavbarModel>(nodesNavBar, locale)

    const footerOptions = mappingEdge<FooterOptionModel>(nodesFooter, locale)

    const { text: textButton1 } = getDataByLocale<FooterButtonText>(nodesTextButton1, locale)

    const filteredLinks = links.filter((link) => !pathsToIgnoreExp.test(link.path))

    const onCookieBannerAccept = () => {
        Cookies.set('gatsby-gdpr-google-tagmanager', 'true')
        Cookies.set('gatsby-gdpr-google-analytics', 'true')
        initializeAndTrack(location)
    }

    const onCookieBannerDecline = () => {
        Cookies.set('gatsby-gdpr-google-tagmanager', 'false')
        Cookies.set('gatsby-gdpr-google-analytics', 'false')
    }

    return (
        <DarkModeProvider>
            <IntersectionObserverProvider options={{ threshold: 0 }}>
                <div className="page">
                    <WhatsAppWidGet locale={locale} />
                    <CookieConsent
                        onDecline={onCookieBannerDecline}
                        flipButtons
                        enableDeclineButton
                        location="bottom"
                        buttonText={locale === Locale.en_US ? 'Accept' : 'Akzeptieren'}
                        declineButtonText={locale === Locale.en_US ? 'Decline' : 'Ablehnen'}
                        cookieName="gatsby-gdpr-google-tagmanager"
                        style={{ backgroundColor: '#585A9D' }}
                        buttonStyle={{ backgroundColor: '#fff', fontSize: '13px' }}
                        onAccept={onCookieBannerAccept}
                    >
                        {constentText[locale]}
                    </CookieConsent>
                    <div className="pageContainer">
                        {metaData && <Seo data={metaData} />}
                        <Navbar
                            darkLogoUrl={darkLogoUrl}
                            logoUrl={logoUrl}
                            contactUs={contactUs}
                            links={filteredLinks}
                            locale={locale}
                            languageLabel={languageLabel}
                            theme={theme}
                        />
                        <main>{children}</main>
                        <Footer options={footerOptions} button={textButton1} locale={locale} />
                    </div>
                </div>
            </IntersectionObserverProvider>
        </DarkModeProvider>
    )
}
