import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import '../../../utils/scss/animations.scss'

const IntersectionObserverContext = createContext(null)

export const IntersectionObserverProvider = ({ children, options }) => {
    const elementsRef = useRef(new Set<Element>())

    const observer = useMemo(() => {
        if (typeof window === 'undefined' || !('IntersectionObserver' in window)) {
            return null
        }
        return new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animated')
                    entry.target.classList.remove('paused')
                    observer.unobserve(entry.target)
                    elementsRef.current.delete(entry.target)
                }
            })
        }, options)
    }, [options])

    useEffect(() => {
        elementsRef.current.forEach((element) => {
            observer.observe(element)
        })

        return () => {
            elementsRef.current.forEach((element) => {
                observer.unobserve(element)
            })
        }
    }, [observer])

    const registerElement = useCallback(
        (element: Element | null, addToChildren = false) => {
            if (element && !elementsRef.current.has(element)) {
                element.classList.add('animatedObject', 'paused')
                elementsRef.current.add(element)
                observer.observe(element)
                if (addToChildren) {
                    element.childNodes.forEach((child) => {
                        if (child instanceof Element) {
                            child.classList.add('animatedObject')
                        }
                    })
                }
            }
        },
        [observer],
    )

    return (
        <IntersectionObserverContext.Provider value={registerElement}>
            {children}
        </IntersectionObserverContext.Provider>
    )
}

export const useIntersectionObserver = () => {
    const context = useContext(IntersectionObserverContext)
    if (context === null) {
        throw new Error(
            'useIntersectionObserver must be used within an IntersectionObserverProvider',
        )
    }
    return context
}
