import React from 'react'

import './Footer.scss'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import {
    TuixButtonComponent,
    TuixLinkComponent,
    TuixTextComponent,
} from 'components/TuixWebcomponents'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Palette, MainVariant, TextSize, TextVariant } from 'tuix-webcomponents'
import { pathsToIgnoreExp } from 'utils/build/pathsConfig'

import { Locale } from '../../utils/enums'
import { getLanguageByLocale } from '../../utils/language'

interface FooterItem {
    anchor: string
    text: string
    redirect: boolean
}

export interface FooterOptionModel {
    node_locale: Locale
    title: string
    link: string
    footerItem: readonly FooterItem[]
}

export interface FooterButtonText {
    node_locale: Locale
    text: string
}

type Props = {
    options: readonly FooterOptionModel[]
    button: string
    locale: Locale
}

export default ({ options, button, locale }: Props): JSX.Element => {
    const lang = `/${getLanguageByLocale(locale)}`
    const year = new Date().getFullYear()

    const { isDarkMode } = useDarkModeContext()
    const { isMobile, isTablet } = useDeviceWidth()

    const companyInfo = () => (
        <TuixTextComponent size={TextSize.Body2} color={TextVariant.LIGHT} dark={isDarkMode}>
            {!isMobile && (
                <address>Tuix GmbH, August-Bebel-Str. 9, 72072, Tübingen | +49 1638 119175</address>
            )}
            {isMobile && (
                <address>
                    Tuix GmbH, August-Bebel-Str. 9, 72072,
                    <br />
                    Tübingen | +49 1638 119175
                </address>
            )}
        </TuixTextComponent>
    )

    return (
        <footer
            className="footer_container"
            style={{ backgroundColor: isDarkMode ? Palette.PRIMARY_800 : Palette.MAIN }}
        >
            <div className="footer_upside">
                <div className="footer_links-container">
                    {options.map((option) => (
                        <div key={option.title} className="column">
                            <Link to={`${lang}${option.link}`} className="row">
                                <TuixLinkComponent
                                    dark={isDarkMode}
                                    variant={MainVariant.SECONDARY}
                                >
                                    <TuixTextComponent size={TextSize.Button}>
                                        {option.title}
                                    </TuixTextComponent>
                                </TuixLinkComponent>
                            </Link>
                            {option.footerItem.map((footerItem) => {
                                if (pathsToIgnoreExp.test(`/${footerItem.anchor}`)) {
                                    return null
                                }

                                return (
                                    <AnchorLink
                                        to={
                                            footerItem.redirect
                                                ? `${lang}/${footerItem.anchor}`
                                                : `${lang}${option.link}#${footerItem.anchor}`
                                        }
                                        key={footerItem.text}
                                        className="row"
                                    >
                                        <TuixLinkComponent
                                            variant={MainVariant.SECONDARY}
                                            dark={isDarkMode}
                                        >
                                            {footerItem.text}
                                        </TuixLinkComponent>
                                    </AnchorLink>
                                )
                            })}
                        </div>
                    ))}
                    <div className="column footer_contact-us">
                        <div className="row">
                            <Link to={`${lang}/contact-us`} className="contact-button_link">
                                <TuixButtonComponent variant={MainVariant.SECONDARY}>
                                    {button}
                                </TuixButtonComponent>
                            </Link>
                        </div>
                    </div>
                </div>
                {(isTablet || isMobile) && companyInfo()}
            </div>
            <div
                style={{ backgroundColor: isDarkMode ? Palette.PRIMARY_950 : Palette.PRIMARY_800 }}
            >
                <div className="footer_copyright">
                    <TuixTextComponent
                        size={TextSize.Body2}
                        color={TextVariant.LIGHT}
                        dark={isDarkMode}
                    >
                        {`Copyright © ${year} Tuix GmbH. All rights reserved.`}
                    </TuixTextComponent>
                </div>
            </div>
        </footer>
    )
}
